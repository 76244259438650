import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from "../pages/Home";
import SignIn from '../user/SignIn';
import AdmHome from '../adm/AdmHome';
import { useSelector } from 'react-redux';
import UploadPOD from '../status/UploadPOD';
import SignOut from '../user/SignOut';
import List_Of_Incoming from '../io/lists/List_Of_Incoming.js';
import List_Of_Outgoing from '../io/lists/List_Of_Outgoing.js';
import IO_Entry_I from '../io/entr/IO_Entry_I.js';
import IO_Entry_O from '../io/entr/IO_Entry_O.js';
import Entr_Status from '../io/entr/Entr_Status.js';

const Mains = () => {
    const admin = useSelector(state => state.softuser)
    if(admin.id.length === 0){
        console.log("no login detail found");
        return <PublicView />
    }

    return (<Admin />);
}

const PublicView = () =>{
    return(
        <div className="container">
        <Router>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/user/sign/in" element={<SignIn />} />
            </Routes>
        </Router>
        </div>
    )
}


const Admin = () => {
    return (<>
        <div className="container">
            <Router>
                <Routes>
                    <Route exact path="/"                   element={<AdmHome />} />
                    <Route exact path="/adm/home"           element={<AdmHome />} />
                    <Route exact path="/status/pod/upload"  element={<UploadPOD />} />

                    <Route exact path="/io/:id/isave"      element={<IO_Entry_I />} />
                    <Route exact path="/io/:id/osave"     element={<IO_Entry_O />} />

                    <Route exact path="/io/in/list"         element={<List_Of_Incoming />} />
                    <Route exact path="/io/out/list"        element={<List_Of_Outgoing/>} />
                    <Route exact path="/io/list/status"     element={<List_Of_Outgoing/>} />
                    <Route exact path="/io/entr/status"     element={<Entr_Status/>} />
                    
                    <Route exact path="/user/sign/in"       element={<SignIn />} />
                    <Route exact path="/user/sign/out"      element={<SignOut />} />
                </Routes>
            </Router>
        </div>
    </>)
}



export default Mains;
