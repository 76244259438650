export const getStrDate = (millis) =>{
    let date    = new Date(millis);
    let dd      = date.getDate();
    let dat     = dd < 10 ? "0"+dd : dd;
    let mm      = date.getMonth()+1; //January is 0!
    let MM      = mm < 10 ? "0"+mm : mm;
    let yyyy    = date.getFullYear();
    let strDate = dat + "-" + MM + "-" + yyyy;
    return strDate;
}

export const getStrDate4Pkr = (millis) =>{
    let date    = new Date(millis);
    let dd      = date.getDate();
    let dat     = dd < 10 ? "0"+dd : dd;
    let mm      = date.getMonth()+1; //January is 0!
    let MM      = mm < 10 ? "0"+mm : mm;
    let yyyy    = date.getFullYear();
    let strDate = yyyy + "-" + MM + "-" + dat;
    return strDate;
}


export const getStrDateTime4Pkr = (millis) =>{
    let date    = new Date(millis);
    let dd      = date.getDate();
    let dat     = dd < 10 ? "0"+dd : dd;
    let mm      = date.getMonth()+1; //January is 0!
    let MM      = mm < 10 ? "0"+mm : mm;
    let yyyy    = date.getFullYear();

    let hr      = date.getHours();
    let hr2     = hr <=12 ? hr : hr - 12;
    let amPM    = hr < 12 ? "AM" : "PM";
    let hour    = hr2 < 10 ? "0"+hr2 : hr2;
    let minit   = date.getMinutes();
    let minute  = minit < 10 ? "0"+minit : minit;

    let strDate = yyyy + "-" + MM + "-" + dat+"T"+hour+":"+minute;
    return strDate;
}

export const getIntDate = (millis) =>{
    let date    = new Date(millis);
    let mm      = date.getMonth()+1; //January is 0!
    let MM      = mm < 10 ? "0"+mm : mm;
    let yyyy    = date.getFullYear();
    let strDate = yyyy +""+ MM;
    let intDate = parseInt(strDate);
    return intDate;
}

export const hasTimeGone = (millis) => {
    let date = Date.now();
    return date > millis ? true : false;
}

export const millis ={
       oneDay : 86400000,
       sevenDays : 604800000,
       thirtyDays : 2592000000
};