import { add2Fs, fsCollection, fsdb, toList, updateFs } from "../fs/fire"

export const createStatus = async(status) =>{
      const ref = await add2Fs(fsCollection.COUREER_STATUS, status);
      return ref;
}

export const updateDelivered = async (cnNo, podLink) =>{
      const db      = fsdb();
      const snap    = await db.collection(fsCollection.COUREER_STATUS).where("cnNo", "==", cnNo).get();
      const list    = toList(snap);

      let statusId  = "";
      for(let i = 0; i < list.length; i++){
        if(podLink === list[i].podLink){
            statusId = list[i].id;
        }
      }

      if(statusId.length === 0){
            const row = {
                cnNo : cnNo, 
                statusCode : 100,
                cnStatus: "DELIVERED",
                deliveredBy: "",
                localId: 1,
                longDate: Date.now(),
                particulars: "SHIPMENT DELIVERED",
                podLink: podLink
            }

            const ref = await add2Fs(fsCollection.COUREER_STATUS, row);
            return ref;
      }

      const data = {
          statusCode : 100,
          cnStatus : "DELIVERED"
      }

      const upd = await updateFs(fsCollection.COUREER_STATUS, statusId, data);
      return upd;

}