import { useRef, useState } from "react";
import { coureer, statusCodes } from "../../db/mdl.coureer";
import { getStrDate, getStrDateTime4Pkr } from "../../utils/dates";
import { getConsignment, getStatusList } from "../../db/fs.coureer";
import { createStatus } from "../../db/fs.coureer.status";

const Entr_Status = () => {
    const[cons, setCons] = useState(coureer);
    const[dtTime, setDtTime]    = useState(getStrDateTime4Pkr(new Date()));
    const[history, setHistory]  = useState([]);
    const[status, setStatus]    = useState({code: 0, status: ""});
    const[statusCode, setStatusCode] = useState(0);

    const tfCnNo        = useRef();
    const tfDate        = useRef();
    const tfStatusCode  = useRef();
    const tfStatus      = useRef();
    const btnSave       = useRef();

    const loadConsignment = async (cnNo) =>{
          const data = await getConsignment(cnNo);
          const hist = await getStatusList(cnNo); console.log(hist);
          if(Object.keys(data).length === 0){
              setCons(coureer);
              setStatus(statusCodes[0]);
              return;
          }

          setCons(data);
          setHistory(hist);
          tfDate.current.focus();
    }

    const changeStatus = (e) =>{
        if(e.key !== "Enter"){
            return;
        }

        const code = parseInt(e.target.value || 0);
        let idx    = statusCodes.findIndex(a=>a.code === code);
        if(idx >= 0){
            setStatus(statusCodes[idx]);
            tfStatus.current.focus();
        }
        else {
            setStatus(statusCodes[0]);
        }
    }

    const saveStatus = async () =>{
        if(cons.cnNo === ""){
            return;
        }

        if(status.code === 0){
            return;
        }

        const data = {
            cnNo        : cons.cnNo,
            statusCode  : status.code,
            cnStatus    : status.status,
            deliveredBy : "",
            localId     : 1,
            longDate    : Date.parse(dtTime),
            particulars : "",
            podLink     : "",
            createOn    : Date.now(),
            createBy    : "",
            modifyOn    : 0,
            modifyBy    : "",
            updateOn    : Date.now(),
        }

        console.log(data);
        // if(1===1){return;}
        const ref = await createStatus(data);
        if(ref.length > 0){
            setStatusCode   (0);
            setStatus       (statusCodes[0]);
            setCons         ({...coureer});
            tfCnNo.current.focus();
            tfCnNo.current.value = "";
        }
    }

    return(<>
        <div className="row">
            <div className="col s12 m6">
                <div className="card">
                    <div className="card-content">
                        <div className="row">
                            <div className="col s12">
                                <span className="card-title">UPDATE STATUS</span>
                            </div>

                            <div className="col s12 m6 input-field">
                                    <input type="text" id="cnNo" 
                                        ref={tfCnNo} 
                                        onKeyDown={e=>{if(e.key === "Enter") loadConsignment(tfCnNo.current.value)}}
                                        />
                                    <label htmlFor="cnNo" className="active">Consignment Number</label>
                            </div>

                            <div className="col s12 m6 input-field">
                                    <input type="datetime-local" id="date" 
                                        ref={tfDate}
                                        onChange={e=>setDtTime(e.target.value)}
                                        onKeyDown={e=>{if(e.key === "Enter"){tfStatusCode.current.focus()}}}
                                        value={getStrDateTime4Pkr(dtTime)} />
                                    <label htmlFor="date" className="active">Date</label>
                            </div>

                            <div className="col s12 m4 input-field">
                                    <input type="text" id="orgCity" 
                                        value={cons.orgCityCode} />
                                    <label htmlFor="orgCity" className="active">From</label>
                            </div>
                            
                            <div className="col s12 m4 input-field">
                                    <input type="text" id="hubCity" value={cons.hubCityCode} />
                                    <label htmlFor="hubCity" className="active">Hub</label>
                            </div>
                            
                            <div className="col s12 m4 input-field">
                                    <input type="text" id="destCity" value={cons.destCityCode}  />
                                    <label htmlFor="destCity" className="active">To</label>
                            </div>

                            <div className="col s12 m6 input-field">
                                    <input type="text" id="status" ref={tfStatusCode}
                                        value={statusCode} 
                                        onChange={e=>setStatusCode(e.target.value ||0)}
                                        onKeyDown={e=>changeStatus(e)}
                                     />
                                    <label htmlFor="status" className="active">Status Code</label>
                            </div>

                            <div className="col s12 m6 input-field">
                                    <input type="text" id="status" ref={tfStatus}
                                        value={status.status} 
                                        onKeyDown={e=>{
                                            if(e.key === "Enter"){
                                                btnSave.current.focus();
                                            }
                                        }}
                                     />
                                    <label htmlFor="status" className="active">Status</label>
                            </div>
                        </div>
                    </div>
                    <div className="card-action">
                        <button ref={btnSave}
                            className="btn btn-flat green white-text" onClick={e=>saveStatus()}>Save</button>
                    </div>
                </div>
            </div>
            <div className="col s12 m6">
                <div className="card">
                    <div className="card-content">
                        <div className="row">
                            <div className="col s12">
                                <span className="card-title">STATUS HISTORY</span>
                            </div>
                            <div className="col s12">
                                <table className="striped">
                                    <thead style={{backgroundColor: "#eeeeee"}}>
                                        <tr>
                                            <th>DATE</th>
                                            <th>STATUS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {history.map((st, i)=><tr key={st.id}>
                                            <td width="130px">{getStrDate(st.longDate)}</td>
                                            <td>{st.cnStatus} <br />{st.particulars}</td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>)
}



export default Entr_Status;